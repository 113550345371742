import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import { Backdrop, Box } from '@mui/material';

import amplitudeHelper from 'helpers/Amplitude';
import { capterraPixel, quoraPixel } from 'helpers/ConversionTrackers';

import { sendData } from './LandingDemoPage';

function isCalendlyEvent(e) {
  return (
    e.origin === 'https://calendly.com' && e.data.event && e.data.event.indexOf('calendly.') === 0
  );
}

export function LandingCalendly({ open, onClose, scheduleEvent }) {
  const [isWidgetInitied, setIsWidgetInitied] = useState(false);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    const handleEventMessage = async (e) => {
      if (isCalendlyEvent(e)) {
        /* Example to get the name of the event */
        console.log('Event name:', e.data.event);

        /* Example to get the payload of the event */
        console.log('Event details:', e.data.payload);

        if (e.data.event === 'calendly.date_and_time_selected') {
          capterraPixel.trackConversion();
          console.log('gtag - demo_time_selected');
          window.gtag('event', 'demo_time_selected');
          amplitudeHelper.logEvent('demo_time_selected', {});
        }

        if (e.data.event === 'calendly.event_type_viewed') {
          amplitudeHelper.logEvent('demo_slot_selected', {});
        }

        if (e.data.event === 'calendly.event_scheduled') {
          localStorage.setItem('calendlyEventScheduled', 'true');
          amplitudeHelper.logEvent('demo_scheduled', {});
          console.log('gtag - demo_scheduled');
          window.gtag('event', 'demo_scheduled');
          quoraPixel.trackEvent('DemoScheduled');
          scheduleEvent();
          try {
            const inviteeURI = e.data.payload.invitee.uri;
            const options = {
              method: 'GET',
              headers: { Authorization: `Bearer ${process.env.REACT_APP_CALENDLY_ACCESS_TOKEN}` }
            };
            const response = await fetch(inviteeURI, options);
            const data = await response.json();
            const { email, name, questions_and_answers } = data.resource;
            sendData({
              firstName: name,
              lastName: '',
              email: email,
              role: questions_and_answers[0].answer,
              company: '',
              utmCampaign: amplitudeHelper.commonParams?.utm_campaign || '—',
              utmMedium: amplitudeHelper.commonParams?.utm_medium || '—',
              utmSource: amplitudeHelper.commonParams?.utm_source || '—'
            });
          } catch (error) {
            console.log('Calendly Error', error);
          }
        }
      }
    };

    window.addEventListener('message', handleEventMessage);

    if (isWidgetInitied === false) {
      // eslint-disable-next-line
      Calendly.initInlineWidget({
        url: 'https://calendly.com/beams-team', //eslint-disable-line
        parentElement: document.getElementById('js-calendly-backdrop'),
        prefill: {
          email: localStorage.getItem('userEmail')
        }
      });
      setIsWidgetInitied(true);
    }

    return () => {
      window.removeEventListener('message', handleEventMessage);
      document.body.style.overflow = 'visible';
    };
  }, []);

  return (
    <Backdrop id='js-calendly-backdrop' open={open} style={{ paddingTop: '60px', zIndex: 2000 }}>
      <Box style={{ position: 'absolute', top: 10, right: 10 }} onClick={onClose}>
        <CloseIcon style={{ cursor: 'pointer', fontSize: 40, color: '#FFF' }} />
      </Box>
    </Backdrop>
  );
}

export const useCalendly = () => {
  const [eventScheduled, setEventScheduled] = useState(false);
  const [displayCalendly, setDisplayCalendly] = useState(false);
  const navigate = useNavigate();

  const scheduleEvent = useCallback(() => {
    setEventScheduled(true);
  });

  const openCalendly = useCallback(() => {
    amplitudeHelper.logEvent('calendly_page_opened', {});
    setDisplayCalendly(true);
  }, []);

  const closeCalendly = useCallback(() => {
    setDisplayCalendly(false);
    if (eventScheduled === true) {
      amplitudeHelper.logEvent('demo_close_button_clicked', {
        after_event: 'true'
      });
      navigate('/thank-you');
    } else {
      amplitudeHelper.logEvent('demo_close_button_clicked', {
        after_event: 'false'
      });
    }
  }, [eventScheduled]);

  return { openCalendly, closeCalendly, scheduleEvent, displayCalendly };
};
LandingCalendly.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  scheduleEvent: PropTypes.func
};
